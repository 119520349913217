/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.required:after {
    content:" *";
    color: red;
  }
  .context-multiline-ellipsis {
	// overflow: hidden !important;
	// display: -webkit-box !important;
	// -webkit-box-orient: vertical !important;
	// -webkit-line-clamp: 3 !important; /* start showing ellipsis when 3rd line is reached */
	// white-space: pre-wrap !important; /* let the text wrap preserving spaces */
	overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important; /* Limit to 3 lines */
    white-space: normal !important; /* Allow normal word wrapping */
    text-overflow: ellipsis !important;
  }